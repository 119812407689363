const theme = {
  primary: "#EA3444",
  primaryLight: "#e84c59",
  primaryDark: "#b12733",
  secondary: "#3a52d8",
  secondaryDark: "#3147b7",
  ternary: "#20232C",
  ternaryDark: "#18191E",
  white: "#FFF",
  black: "#000",
  grey: "#9b9b9b",
  greyLight: "#e2e2e2",
  greyLighter: "#F9F9F9",
  greyDarker: "#4a4a4a",
  error: "#dc3545",
  bodyColor: "#676B70",
  headerColor: "#17191F",
}

export default theme
