import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

//import Container from "../elements/Container"
import globalVariables from "../globalVariables"
import MenuItem from "./MenuItem"
import { ShoppingCart } from "styled-icons/feather/ShoppingCart"

import Logo from "../../images/logo-personal-trainer.png"

const Header = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @media (min-width: ${globalVariables.minTablet}) {
    position: fixed;
    z-index: 2;
  }
`

const Nav = styled.nav`
  background-color: ${props =>
    props.isNavbarTransparent ? "transparent" : props.theme.ternary};
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  transition: all 0.2s ease-in-out;
  /* @media (min-width: ${globalVariables.minTablet}) {
    background-color: ${props =>
      props.isNavbarTransparent ? "transparent" : props.theme.ternary};
  } */
`

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
`

const WrapperBurger = styled.button`
  cursor: pointer;
  z-index: 4;
  width: 40px;
  display: block;
  background: transparent;
  border: none;
  outline: none !important;
  padding: 0;
  @media (min-width: ${globalVariables.minTablet}) {
    display: none;
  }
`

const BurgerBar = styled.span`
  display: block;
  width: 100%;
  height: 4px;
  background-color: white;
  border-radius: 4px;
  margin: 8px 0;
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: 1px solid ${props => props.theme.ternary};
  &:nth-child(1) {
    transform: ${props =>
      props.isBurgerActive
        ? "rotate(-405deg) translate(-10px,7px)"
        : "#rotate(0deg) translate(0,0)"};
  }
  &:nth-child(2) {
    background-color: ${props =>
      props.isBurgerActive ? "transparent" : "white"};
  }
  &:nth-child(3) {
    transform: ${props =>
      props.isBurgerActive
        ? "rotate(405deg) translate(-10px,-7px)"
        : "#rotate(0deg) translate(0,0)"};
  }
`

const WrapperNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100px;

  @media (min-width: ${globalVariables.minTablet}) {
    min-height: ${globalVariables.navbarHeight};
  }
`

const LinkStyled = styled(Link)`
  text-decoration: none;
  z-index: 1;
`

// const LogoText = styled.p`
//   font-family: "Rajdhani";
//   text-transform: uppercase;
//   color: ${props => props.theme.white};
//   font-size: 20px;
//   font-weight: bold;
// `

const LogoStyled = styled.img`
  width: 50px;
  height: 50px;
`

const Menu = styled.ul`
  @media (max-width: ${globalVariables.maxMobile}) {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    padding: 50px 40px 30px;
    transform: ${props =>
      props.isBurgerActive ? "translateX(0)" : "translateX(100%)"};
    opacity: ${props => (props.isBurgerActive ? "1" : "0")};
    z-index: ${props => (props.isBurgerActive ? "1" : "0")};
    width: 300px;
    height: 100%;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
    background-color: ${props => props.theme.ternary};
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    flex-direction: column;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    display: flex;
    align-items: center;
  }
`

const WrapperCheckout = styled.a`
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 15px;
`

const ShoppingCartStyled = styled(ShoppingCart)`
  color: ${props => props.theme.white};
`

// const WrapperSummary = styled.div`
//   position: absolute;
//   top: -10px;
//   right: -10px;
//   width: 10px;
//   height: 10px;
//   background: ${props => props.theme.primary};
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   border-radius: 50%;
//   padding: 8px;
//   span {
//     color: ${props => props.theme.white};
//     font-size: 12px;
//   }
// `

const Navbar = ({ isNavbarTransparent }) => {
  const [isBurgerActive, setToggleMenu] = useState(false)
  const [isBackgroundColored, setIsBackgroundColored] = useState(false)

  const handleScroll = () => {
    const currentScrollY = window.scrollY

    if (currentScrollY > 50) {
      setIsBackgroundColored(true)
    } else {
      setIsBackgroundColored(false)
    }
  }

  useEffect(() => {
    if (isNavbarTransparent) {
      window.addEventListener("scroll", handleScroll)

      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  const menuData = [
    {
      title: "Accueil",
      url: "/",
    },
    {
      title: "Coaching",
      url: "/coaching-sportif-personnalise",
    },
    {
      title: "E-book",
      url: "/e-books",
    },
    {
      title: "À Propos",
      url: "/a-propos",
    },
    {
      title: "Blog",
      url: "/blog",
    },
    {
      title: "Contact",
      url: "/contact",
    },
  ]

  return (
    <Header>
      <Nav isNavbarTransparent={isNavbarTransparent && !isBackgroundColored}>
        <Container>
          <WrapperNav>
            <LinkStyled to="/">
              <LogoStyled src={Logo} />
              {/* <LogoText>KEVCOACHINGPRO</LogoText> */}
            </LinkStyled>

            <WrapperBurger onClick={e => setToggleMenu(!isBurgerActive)}>
              <BurgerBar isBurgerActive={isBurgerActive} />
              <BurgerBar isBurgerActive={isBurgerActive} />
              <BurgerBar isBurgerActive={isBurgerActive} />
            </WrapperBurger>

            <Menu isBurgerActive={isBurgerActive}>
              {menuData.map((item, index) => (
                <MenuItem key={"item" + index} item={item} />
              ))}
              <WrapperCheckout href="#" className="snipcart-checkout">
                <ShoppingCartStyled />
                {/* <WrapperSummary className="snipcart-summary">
                  <span className="snipcart-total-items"></span>
                </WrapperSummary> */}
              </WrapperCheckout>
            </Menu>
          </WrapperNav>
        </Container>
      </Nav>
    </Header>
  )
}

export default Navbar
