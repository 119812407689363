import { css } from "styled-components"

export const getVariantButtonColors = props => {
  let background = props.theme.primary
  let color = props.theme.white
  let border = props.theme.primary
  let backgroundHover = props.theme.secondary
  let colorHover = props.theme.white
  let borderHover = props.theme.secondary
  let padding
  let fontSize
  let minHeight

  if (props.colors === "primaryWithBg") {
    background = props.theme.primary
    color = props.theme.white
    border = props.theme.primary
    backgroundHover = props.theme.primaryDark
    colorHover = props.theme.white
    borderHover = props.theme.primaryDark
  }
  if (props.colors === "secondaryWithBg") {
    background = props.theme.secondary
    color = props.theme.white
    border = props.theme.secondary
    backgroundHover = props.theme.secondaryDark
    colorHover = props.theme.white
    borderHover = props.theme.secondaryDark
  }
  if (props.colors === "errorWithBg") {
    background = props.theme.error
    color = props.theme.white
    border = props.theme.error
    backgroundHover = props.theme.white
    colorHover = props.theme.error
    borderHover = props.theme.error
  }
  if (props.colors === "primary") {
    background = "transparent"
    color = props.theme.primary
    border = props.theme.primary
    backgroundHover = "transparent"
    colorHover = props.theme.secondary
    borderHover = props.theme.secondary
  }
  if (props.colors === "secondary") {
    background = "transparent"
    color = props.theme.secondary
    border = props.theme.secondary
    backgroundHover = "transparent"
    colorHover = props.theme.primary
    borderHover = props.theme.primary
  }
  if (props.colors === "error") {
    background = "transparent"
    color = props.theme.error
    border = props.theme.error
    backgroundHover = props.theme.error
    colorHover = props.theme.white
    borderHover = props.theme.error
  }
  if (props.colors === "link") {
    background = "transparent"
    color = props.theme.primary
    border = "transparent"
    backgroundHover = "transparent"
    colorHover = props.theme.secondary
    borderHover = "transparent"
    padding = "0"
    fontSize = "0.9rem"
    minHeight = "unset"
  }

  return css`
    border-color: ${border};
    background: ${background};
    color: ${color};
    padding: ${padding};
    font-size: ${fontSize};
    min-height: ${minHeight};
    &:hover,
    &:active,
    &:focus {
      border-color: ${borderHover};
      background: ${backgroundHover};
      color: ${colorHover};
    }
    span {
      color: ${color};
    }
  `
}
