import Kevin from "../images/Kevin-footer.png"

export const getSchemaOrgJSONLD = ({
  isBlogPost,
  isProduct,
  url,
  title,
  image,
  description,
  datePublished,
  price,
}) => {
  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url,
      name: title,
      alternateName: "Kevin Jehanno - Coaching sportif personnel",
    },
  ]

  return isBlogPost
    ? [
        ...schemaOrgJSONLD,
        {
          "@context": "https://kevinjehanno-coaching.com/",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": url,
                name: title,
                image,
              },
            },
          ],
        },
        {
          "@context": "https://kevinjehanno-coaching.com/",
          "@type": "BlogPosting",
          url,
          name: title,
          alternateName: "Kevin Jehanno - Coaching sportif personnel",
          headline: title,
          image: {
            "@type": "ImageObject",
            url: image,
          },
          description,
          author: {
            "@type": "Person",
            name: "Kevin Jehanno",
          },
          publisher: {
            "@type": "Organization",
            url: "https://kevinjehanno-coaching.com/",
            logo: Kevin,
            name: "Kevin Jehanno",
          },
          mainEntityOfPage: {
            "@type": "WebSite",
            "@id": "https://kevinjehanno-coaching.com/",
          },
          datePublished,
        },
      ]
    : isProduct
    ? [
        ...schemaOrgJSONLD,
        {
          "@context": "https://kevinjehanno-coaching.com/",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": url,
                name: title,
                image,
              },
            },
          ],
        },
        {
          "@context": "https://kevinjehanno-coaching.com/",
          "@type": "Product",
          name: title,
          image: {
            "@type": "ImageObject",
            url: image,
          },
          description,
          url,
          brand: {
            "@type": "Brand",
            name: "KevCoachingPro",
          },
          offers: {
            "@type": "Offer",
            price: price,
            priceCurrency: "euro",
          },
          publisher: {
            "@type": "Organization",
            url: "https://kevinjehanno-coaching.com/",
            logo: Kevin,
            name: "Kevin Jehanno",
          },
          mainEntityOfPage: {
            "@type": "WebSite",
            "@id": "https://kevinjehanno-coaching.com/",
          },
          datePublished,
        },
      ]
    : schemaOrgJSONLD
}
