import React from "react"
import styled from "styled-components"

import globalVariables from "../../globalVariables"

const SectionStyled = styled.section`
  position: relative;
  padding: ${props => (props.padding ? props.padding : "50px 0")};
  background-color: ${props =>
    props.color ? props.theme[props.color] : "inherit"};

  .gatsby-image-wrapper {
    height: ${props =>
      props.imageMobileHeight ? props.imageMobileHeight : "unset"};
  }

  @media (min-width: ${globalVariables.minTablet}) {
    padding: ${props => (props.padding ? props.padding : "100px 0")};
    margin-top: ${props =>
      props.isfirstBlock ? globalVariables.navbarHeight : "0"};
    .gatsby-image-wrapper {
      height: ${props => (props.imageHeight ? props.imageHeight : "unset")};
    }
    img {
      object-position: ${props =>
        props.objectPosition ? props.objectPosition : "center center"};
    }
  }
`

const Section = ({
  color,
  padding,
  isfirstBlock,
  imageHeight,
  imageMobileHeight,
  objectPosition,
  children,
}) => {
  return (
    <SectionStyled
      color={color}
      padding={padding}
      isfirstBlock={isfirstBlock}
      imageHeight={imageHeight}
      imageMobileHeight={imageMobileHeight}
      objectPosition={objectPosition}
    >
      {children}
    </SectionStyled>
  )
}

export default Section
