import React, { useState } from "react"
import styled from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"
import isEmail from "validator/lib/isEmail"

import Input from "./Input"
import globalVariables from "../../globalVariables"
import { LocalPostOffice } from "styled-icons/material/LocalPostOffice"

const Form = styled.form`
  margin: ${props => (props.margin ? props.margin : "auto")};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  @media (min-width: ${globalVariables.medDesktop}) {
    max-width: ${props => (props.maxWidth ? props.maxWidth : "400px")};
  }
  & > div {
    box-shadow: inset 0px 0px 0px 2px ${props => props.theme.white};
  }
`

const ButtonGroup = styled.button`
  border: none;
  background: ${props => props.theme.secondary};
  color: white;
  font-weight: bold;
  padding: 0 14px;
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px;
  transition: all 0.2s ease-in-out;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Rajdhani;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
    background: ${props => props.theme.secondaryDark};
  }
  &:focus {
    outline: none;
  }
`

const ValidationMessage = styled.span`
  position: absolute;
  bottom: -30px;
  left: 0;
  font-size: 90%;
  color: ${props => props.theme[props.color]};
`

const LocalPostOfficeStyled = styled(LocalPostOffice)`
  color: ${props => props.theme.white};
  width: 20px;
  height: 20px;
  margin-right: 10px;
`

const Newsletter = ({ margin, maxWidth, icon }) => {
  const [email, setEmail] = useState({
    email: "",
    submit: false,
    sendToMailchimp: false,
  })

  const updateInputData = event => {
    setEmail({ ...email, email: event.target.value })
  }

  const handleSubmit = event => {
    event.preventDefault()
    // on verifie que le format e-mail est valide
    if (isEmail(email.email)) {
      addToMailchimp(email.email)
        .then(data => {
          // I recommend setting data to React state
          setEmail({ email: "", sendToMailchimp: true })
        })
        .catch(() => {
          // unnecessary because Mailchimp only ever
          // returns a 200 status code
          // see below for how to handle errors
        })
    } else {
      setEmail({ ...email, submit: true })
    }
  }
  // console.log(email);
  return (
    <Form onSubmit={handleSubmit} margin={margin} maxWidth={maxWidth}>
      <Input
        type="email"
        placeholder="Adresse e-mail"
        value={email.email}
        handleChange={e => updateInputData(e)}
        name="email"
        required
        fullWidth
        id="email"
        inputGroup={true}
      />
      {!isEmail(email.email) && email.submit && (
        <ValidationMessage color="error">
          Veuillez rentrer un e-mail valide.
        </ValidationMessage>
      )}
      {email.sendToMailchimp && (
        <ValidationMessage color="secondary">
          Merci, votre e-mail a bien été enregistré !
        </ValidationMessage>
      )}
      <ButtonGroup type="submit">
        {" "}
        {icon && <LocalPostOfficeStyled />}Envoyer
      </ButtonGroup>
    </Form>
  )
}

export default Newsletter
