import React from "react"
import styled from "styled-components"

const SectionStyled = styled.section`
  background-color: ${props => props.theme.white};
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  iframe {
    margin: 0;
  }
`

const Container = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`

const Trustpilot = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null)
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <SectionStyled>
      <Container>
        {/* TRUSTPILOT */}
        <div
          ref={ref} // We need a reference to this element to load the TrustBox in the effect.
          className="trustpilot-widget"
          data-locale="fr-FR"
          data-template-id="5419b6a8b0d04a076446a9ad"
          data-businessunit-id="5ca9e8693ae88b0001ecbec2"
          data-style-height="24px"
          data-style-width="100%"
          data-theme="light"
        >
          <a
            href="https://fr.trustpilot.com/review/kevinjehanno-coaching.com"
            target="_blank"
            rel="noopener"
          >
            Trustpilot
          </a>
        </div>
      </Container>
    </SectionStyled>
  )
}

export default Trustpilot
