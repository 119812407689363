import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import globalVariables from "../globalVariables"

export const Item = styled.li`
  list-style: none;
  padding: 0 25px;
  margin-bottom: 35px;
  a,
  button {
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.white};
    transition: all 0.2s ease-in-out;
    font-family: "Rajdhani";
    text-transform: uppercase;
    font-size: 18px;
    position: relative;
    z-index: 0;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -2px;
      left: 0;
      z-index: -1;
      transform: scaleX(0);
      transform-origin: right center 0px;
      background: ${props => props.theme.primary};
      transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    }
  }
  .active:not(.highlight)::after {
    transform: scaleX(1);
    transform-origin: left center 0px;
    transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68) 0s;
  }
  .active {
    /* text-decoration: underline; */
    font-weight: bold;
  }
  .highlight {
    background: ${props => props.theme.primary};
    border-radius: ${globalVariables.borderRadiusButton};
    padding: 2px 14px;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: ${props => props.theme.primaryDark};
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    margin-bottom: 0;
    padding: 0 10px;

    a:not(.highlight):hover::after {
      transform: scaleX(1);
      transform-origin: left center 0px;
      transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68) 0s;
    }
    a,
    button {
      font-size: 16px;
    }
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    padding: 0 25px;
    a,
    button {
      font-size: 18px;
    }
  }
`

const MenuItem = ({ item }) => {
  return (
    <Item>
      <Link
        to={item.url}
        activeClassName="active"
        className={item.url === "/contact" ? "highlight" : null}
      >
        {item.title}
      </Link>
    </Item>
  )
}

export default MenuItem
