const globalVariables = {
  borderRadius: "1px",
  borderRadiusButton: "35px",
  borderWidth: "2px",
  navbarHeight: "56px",
  maxMobile: "767px",
  minTablet: "768px",
  maxTablet: "991px",
  minDesktop: "992px",
  medDesktop: "1220px",
  bigDesktop: "1900px",
}

export default globalVariables
