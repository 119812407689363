/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import Banner from "../images/kevcoachingpro.png"
import { getSchemaOrgJSONLD } from "./schema"

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  isBlogPost,
  url,
  image,
  datePublished,
  isProduct,
  price,
}) {
  const metaDescription =
    description ||
    `Découvrez mes formules de coaching sportifs à Nantes ou à distance ainsi que mes e-books pour vous aider à atteindre vos objectifs !`

  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    isBlogPost,
    url,
    title,
    image,
    description,
    datePublished,
    isProduct,
    price,
  })

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | KevCoachingPro`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: Banner,
        },
        {
          property: `og:url`,
          content: `https://kevinjehanno-coaching.com/`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          property: `og:site_name`,
          content: `KevCoachingPro`,
        },
        {
          name: `twitter:creator`,
          content: `@gatsbyjs`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        // {
        //   name: `google-site-verification`,
        //   content: ``
        // }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
