/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import { ThemeProvider } from "styled-components"
import styled from "styled-components"
import Helmet from "react-helmet"

// import { ModalProvider, BaseModalBackground } from "styled-react-modal";

import theme from "./theme"
import Navbar from "./Navbar"
import Footer from "./Footer"
import Trustpilot from "./Footer/Trustpilot"
import "./layout.css"
import GlobalStyles from "./GlobalStyles"

const Wrapper = styled.div`
  overflow: hidden;
  .dropdown-enter-done {
    transform: translateY(0%);
    opacity: 1;
  }

  .dropdown-exit-active {
    transform: translateY(-100%);
    opacity: 0;
  }
`

// const FadingBackground = styled(BaseModalBackground)`
//   opacity: ${props => props.opacity};
//   transition: opacity ease 200ms;
// `;

const Layout = ({
  navbar,
  footer,
  isNavbarTransparent,
  kevinFooter,
  instagram,
  children,
}) => {
  return (
    <ThemeProvider theme={theme}>
      {/* <ModalProvider backgroundComponent={FadingBackground}> */}

      <Wrapper>
        <Helmet htmlAttributes={{ lang: "fr" }}>
          {/* TEST SNIPCART CODE */}

          {/* <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.2.2/jquery.min.js"></script>
          <script
            src="https://cdn.snipcart.com/scripts/2.0/snipcart.js"
            data-api-key="ZDU3ZThjNWEtYTVmZS00OTE2LTlkNmQtZWY4YTViMWNmOWZlNjM2OTk0ODU5MjUxNTM4NDMy"
            id="snipcart"
          ></script>
          <link
            href="https://cdn.snipcart.com/themes/2.0/base/snipcart.min.css"
            rel="stylesheet"
            type="text/css"
          /> */}
          {/* LIVE SNIPCART CODE */}
          <script src="https://code.jquery.com/jquery-3.4.1.min.js" integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo=" crossorigin="anonymous"></script>
          <script src="https://cdn.snipcart.com/scripts/2.0/snipcart.js" id="snipcart" data-api-key="NWIxM2QwNzQtOGUyYi00YTZkLWI1MGEtNjRlZjU5MDgyNzg3NjM2OTk0ODU5MjUxNTM4NDMy"></script>
          <link href="https://cdn.snipcart.com/themes/2.0/base/snipcart.min.css" type="text/css" rel="stylesheet" />
          <script
            type="text/javascript"
            src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          ></script>


        </Helmet>
        <GlobalStyles />
        <Navbar data={navbar} isNavbarTransparent={isNavbarTransparent} />
        {children}
        <Trustpilot />
        <Footer data={footer} kevinFooter={kevinFooter} instagram={instagram} />
      </Wrapper>
      {/* </ModalProvider> */}
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
