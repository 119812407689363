import React from "react"
import { createGlobalStyle } from "styled-components"
import globalVariables from "./globalVariables"
import theme from "./theme"
require("typeface-metropolis")
require("typeface-rajdhani")

const GlobalStyles = () => {
  const GlobalDefaults = createGlobalStyle`
    
    body {
      h1, h2, h3, h4, h5, h6 {
        font-family: 'Rajdhani', "Helvetica", "Arial", sans-serif;
        text-transform: uppercase;
        font-weight: bold;
      }
      p, a, span, button, li, input, textarea {
        font-size: 14px;
        margin: 0;
        font-family: 'Metropolis', "Helvetica", "Arial", sans-serif;
  
        @media (min-width: ${globalVariables.minDesktop}) {
          font-size: 16px;
        }
        @media (min-width: ${globalVariables.bigDesktop}) {
          font-size: 18px;
        }
      }
      strong {
        font-weight: bold;
      }
      a {
        color: ${theme.black};
        text-decoration: underline;
        text-decoration-color: ${theme.darkGreen};
        cursor: pointer;
        transition: all 0.2s linear;
        &:hover {
          color: ${theme.darkGreen};
        }
      }
      button:focus, input:focus, textarea:focus, div:focus {
        outline: none;
      }
    }
    
  `

  return <GlobalDefaults />
}

export default GlobalStyles
