import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Link from "gatsby-link"
import axios from "axios"

// import Container from "../elements/Container"
import globalVariables from "../globalVariables"
import { Facebook } from "styled-icons/boxicons-logos/Facebook"
import { Instagram } from "styled-icons/boxicons-logos/Instagram"
import { Youtube } from "styled-icons/fa-brands/Youtube"
import Newsletter from "../Commun/Newsletter"

import Picture1 from "../../images/footer/insta1.jpeg"
import Picture2 from "../../images/footer/insta2.jpg"
import Picture3 from "../../images/footer/insta3.jpg"
import Picture4 from "../../images/footer/insta4.jpg"
import Picture5 from "../../images/footer/insta5.jpg"
import Picture6 from "../../images/footer/insta6.jpg"
import Picture7 from "../../images/footer/insta7.jpg"
import Picture8 from "../../images/footer/insta8.jpg"

const FooterStyled = styled.footer`
  font-size: 0.8rem;
  line-height: 20px;
  background-color: ${props => props.theme.ternary};
  padding-top: 2rem;
`

const Container = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
  }
`

const Item = styled.div`
  flex: ${props => (props.flex ? props.flex : "1 1 auto")};
  padding: 1rem;
  &:not(:last-child) {
    margin-right: 1rem;
  }
  .gatsby-image-wrapper {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
`

const SubItem = styled.div`
  margin-bottom: 2rem;
`

const Title = styled.p`
  color: ${props => props.theme.white};
  font-family: Rajdhani;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1rem;
`

const Text = styled.p`
  color: ${props => props.theme.white};
  margin-bottom: 1rem;
  max-width: 250px;
  font-size: 14px;
`
const Copyright = styled.p`
  color: ${props => props.theme.white};
  margin: 1rem auto;
  font-size: 12px;
`

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.white};
  font-size: 14px;
  display: block;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.secondary};
  }
`

// const ExternalLink = styled(LinkStyled)``

const IconList = styled.div`
  display: flex;
  flex-direction: row;
`

const IconWrapper = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.primary};
  border-radius: 4px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  &:hover {
    background: ${props => props.theme.secondary};
  }
`

const FacebookStyled = styled(Facebook)`
  color: ${props => props.theme.white};
  width: 20px;
  height: 20px;
`
const InstagramStyled = styled(Instagram)`
  color: ${props => props.theme.white};
  width: 20px;
  height: 20px;
`
const YoutubeStyled = styled(Youtube)`
  color: ${props => props.theme.white};
  width: 20px;
  height: 20px;
`

const ContainerInsta = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`

const WrapperInsta = styled.div`
  flex: 0 0 50%;
  img {
    width: 100%;
    height: 100%;
    padding: 0 0.5rem 0.5rem 0;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    flex: 0 0 25%;
    img {
      width: 100%;
      height: 100%;
      padding: 0 1rem 1rem 0;
    }
  }
  @media (min-width: ${globalVariables.bigDesktop}) {
    flex: 0 0 20%;
    display: ${props => (props.index > 4 ? "none" : "block")};
  }
`

// const WrapperInsta = styled.div`
//   flex: 0 0 50%;

//   @media (min-width: ${globalVariables.minDesktop}) {
//     flex: 0 0 25%;
//   }
//   @media (min-width: ${globalVariables.bigDesktop}) {
//     flex: 0 0 20%;
//     display: ${props => (props.index > 4 ? "none" : "block")};
//   }
// `

// const Image = styled.div`
//   width: 90px;
//   height: 90px;
//   overflow: hidden;
//   /* margin: 0 0.5rem 0.5rem 0; */
//   background-image: url(${props => props.src});
//   background-repeat: no-repeat;
//   background-size: cover;
//   @media (min-width: ${globalVariables.minDesktop}) {
//     width: 150px;
//     height: 150px;
//     /* margin: 0 1rem 1rem 0; */
//   }
// `

const Footer = ({ kevinFooter }) => {
  // const [instaPhotos, setInstaPhotos] = useState()
  // useEffect(() => {
  //   // It will contain our photos' links
  //   if (!instaPhotos) {
  //     const res = []

  //     axios.get("https://www.instagram.com/kevcoachingpro/").then(result => {
  //       const jsonObject = result.data
  //         .match(
  //           /<script type="text\/javascript">window\._sharedData = (.*)<\/script>/
  //         )[1]
  //         .slice(0, -1)
  //       const userInfo = JSON.parse(jsonObject)
  //       // Retrieve only the first 10 results
  //       console.log(userInfo)
  //       const mediaArray = userInfo.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges.splice(
  //         0,
  //         8
  //       )
  //       for (let media of mediaArray) {
  //         const node = media.node

  //         // Process only if is an image
  //         // if (
  //         //   (node.__typename && node.__typename !== "GraphImage")
  //         // ) {
  //         //   continue
  //         // }

  //         // Push the thumbnail src in the array
  //         res.push(node.thumbnail_src)
  //       }
  //       setInstaPhotos(res)
  //       return res
  //     })
  //   }
  // }, [])

  return (
    <FooterStyled>
      <Container>
        <Wrapper>
          <Item flex="0 1 auto">
            <Title>À Propos</Title>
            <Img fixed={kevinFooter} />
            <Title>Kevin Jehanno</Title>
            <Text>
              Votre coach sportif personnel en salle (Nantes) ou en ligne. Je
              vous accompagne dans la réalisation de vos objectifs !
            </Text>
            <Copyright>©2022 Kevin Jehanno. All Rights Reserved</Copyright>
          </Item>
          <Item flex="1 1 40%">
            <Title>Instagram</Title>
            <ContainerInsta>
              <WrapperInsta>
                <img alt={`instagram Kevin Jehanno `} src={Picture1} />
              </WrapperInsta>
              <WrapperInsta>
                <img alt={`instagram Kevin Jehanno `} src={Picture2} />
              </WrapperInsta>
              <WrapperInsta>
                <img alt={`instagram Kevin Jehanno `} src={Picture3} />
              </WrapperInsta>
              <WrapperInsta>
                <img alt={`instagram Kevin Jehanno `} src={Picture4} />
              </WrapperInsta>
              <WrapperInsta>
                <img alt={`instagram Kevin Jehanno `} src={Picture5} />
              </WrapperInsta>
              <WrapperInsta>
                <img alt={`instagram Kevin Jehanno `} src={Picture6} />
              </WrapperInsta>
              <WrapperInsta>
                <img alt={`instagram Kevin Jehanno `} src={Picture7} />
              </WrapperInsta>
              <WrapperInsta>
                <img alt={`instagram Kevin Jehanno `} src={Picture8} />
              </WrapperInsta>
              {/* {instaPhotos &&
                instaPhotos.map((item, index) => (
                  <WrapperInsta key={index} index={index}>
                    <img
                      alt={`instagram Kevin Jehanno - ${index}`}
                      src={item}
                    />
                  </WrapperInsta>
                ))} */}
            </ContainerInsta>
          </Item>
          <Item flex="0 1 auto">
            <SubItem>
              <Title>Liens utiles</Title>
              <LinkStyled to="/contact">Contact</LinkStyled>
              <LinkStyled to="/mentions-legales">Mentions Légales</LinkStyled>
            </SubItem>

            <SubItem>
              <Title>Suivez-moi</Title>
              <IconList>
                <IconWrapper
                  href="https://www.facebook.com/kevcoachingpro/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookStyled />
                </IconWrapper>
                <IconWrapper
                  href="https://www.instagram.com/kevcoachingpro/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramStyled />
                </IconWrapper>
                {/* <IconWrapper
                  href="https://www.youtube.com/channel/UCgWw8Ln8TD7_LNTJmw1LH4g/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YoutubeStyled />
                </IconWrapper> */}
              </IconList>
            </SubItem>
            <SubItem>
              <Title>Newsletter</Title>
              <Newsletter margin="0" maxWidth="350px" />
            </SubItem>
          </Item>
        </Wrapper>
      </Container>
    </FooterStyled>
  )
}

export default Footer
